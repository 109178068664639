.options-menu-button .tide-button {
  padding: 6px 10px 4px;
  height: 32px;
}

.options-menu-button .icon-left .tide-icon {
  margin: 0;
  transform: scale(1.5);
}

.options-menu-button .tide-action-dropdown-actions {
  right: 0;
}
