.gif-preview {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
}

.gif-preview-image,
.gif-failure-image {
  max-width: 100%;
  max-height: 100%;
  min-width: 50%;
  box-shadow: var(--box-shadow-3);
}
